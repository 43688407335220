<template>
  <div>
    <div v-for="(passage, index) in list" :key="index">
      <h4 v-if="practiceType === 'composePractices'">
        {{ getCompanyExamTitle(passage.exam.title) }}:
        <span>
          <span style="color: #ff8920;">{{ passage.correctCount }}</span
          >/{{ passage.exam.exam_questions.length }}</span
        >
        <span class="ml-2 text-info">[{{ sectionTotalTime }}]</span>
      </h4>
      <div
        class="answer-table"
        v-for="i in Math.ceil(passage.exam.exam_questions.length / lineCount)"
        :key="i"
      >
        <table class="table table-bordered">
          <thead>
            <tr class="rnum_1">
              <th class="green-td">#</th>
              <!-- <th class="green-td">Your Answer</th> -->
              <th class="green-td">Correct Answer</th>
              <th class="green-td">Answer Time</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(question, index) in passage.exam.exam_questions"
              :key="question.order"
              v-show="(i - 1) * lineCount <= index && index < i * lineCount"
            >
              <td class="green-td">{{ question.order }}</td>

              <!-- <td>
                <router-link
                  v-if="question.userAnswer"
                  :to="{
                    name: 'QuestionExplanation',
                    params: {
                      questionId: question.question_id
                    },
                    query: {
                      user_exam_id: userExamId,
                      practiceType: practiceType,
                      order: index + 1,
                      passageIndex: passageIndex + 1,
                      user_exam_answer_id: question.userAnswer
                        ? question.userAnswer.id
                        : null
                    }
                  }"
                >
                  <div
                    :class="
                      question.userAnswer.is_correct === 0 ? 'text-danger' : ''
                    "
                  >
                    <el-tooltip
                      v-if="question.userAnswer.answers[0]"
                      class="item"
                      effect="dark"
                      :disabled="
                        question.userAnswer.user_exam_answer_analysis
                          ? false
                          : true
                      "
                      :content="
                        showTime(
                          question.userAnswer.user_exam_answer_analysis
                            ? question.userAnswer.user_exam_answer_analysis
                                .answer_time
                            : 0
                        )
                      "
                      placement="top"
                    >
                      <div>
                        {{ question.userAnswer.answers[0] }}
                      </div>
                    </el-tooltip>
                    <div v-else>*</div>
                  </div>
                </router-link>
              </td> -->
              <td>
                <router-link
                  :to="{
                    name: 'QuestionExplanation',
                    params: {
                      questionId: question.question_id
                    },
                    query: {
                      user_exam_id: userExamId,
                      practiceType: practiceType,
                      order: index + 1,
                      passageIndex: passageIndex + 1,
                      user_exam_answer_id: question.userAnswer
                        ? question.userAnswer.id
                        : null
                    }
                  }"
                >
                  <template v-if="question.userAnswer">
                    <template v-if="question.userAnswer.answers[0]">
                      <div
                        class="text-success"
                        v-if="question.userAnswer.is_correct === 1"
                      >
                        <i class="fa fa-check"></i>
                      </div>
                      <div v-else class="text-danger">
                        <i class="fa fa-times"></i>
                      </div>
                    </template>
                    <div v-else class="text-danger">
                      <i class="fa fa-times"></i>
                    </div>
                  </template>
                  <b style="color: #ccc;" v-else>
                    /
                  </b>
                  <!-- <div
                    :class="
                      question.userAnswer.is_correct === 0 ? 'text-danger' : ''
                    "
                  >
                    <el-tooltip
                      v-if="question.userAnswer.answers[0]"
                      class="item"
                      effect="dark"
                      :disabled="
                        question.userAnswer.user_exam_answer_analysis
                          ? false
                          : true
                      "
                      :content="
                        showTime(
                          question.userAnswer.user_exam_answer_analysis
                            ? question.userAnswer.user_exam_answer_analysis
                                .answer_time
                            : 0
                        )
                      "
                      placement="top"
                    >
                      <div>
                        {{ question.userAnswer.answers[0] }}
                      </div>
                    </el-tooltip>
                    <div v-else>*</div>
                  </div> -->
                </router-link>
              </td>
              <td>
                <router-link
                  :to="{
                    name: 'QuestionExplanation',
                    params: {
                      questionId: question.question_id
                    },
                    query: {
                      user_exam_id: userExamId,
                      practiceType: practiceType,
                      order: index + 1,
                      passageIndex: passageIndex + 1,
                      user_exam_answer_id: question.userAnswer
                        ? question.userAnswer.id
                        : null
                    }
                  }"
                >
                  <span class="text-info" v-if="question.userAnswer">
                    {{
                      question.userAnswer.other_status
                        ? showTotalTime(
                            question.userAnswer.other_status.activities
                          )
                        : "-"
                    }}
                  </span>
                </router-link>
              </td>
              <!-- <td>
                <router-link
                  :to="{
                    name: 'QuestionExplanation',
                    params: {
                      questionId: question.question_id
                    },
                    query: {
                      user_exam_id: userExamId,
                      practiceType: practiceType,
                      order: index + 1,
                      passageIndex: passageIndex + 1,
                      user_exam_answer_id: question.userAnswer
                        ? question.userAnswer.id
                        : null
                    }
                  }"
                >
                  {{ showAnswers(question.question.sat_answers) }}
                </router-link>
              </td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Common from "@/mixins/common.js";
import { instant } from "@ivy-way/material";

export default {
  components: {},

  mixins: [Common],

  props: [
    "examId",
    "list",
    "score",
    "raw",
    "count",
    "userExamId",
    "practiceType",
    "passageIndex"
  ],
  data() {
    return {
      lineCount: 10
    };
  },
  computed: {
    instant() {
      return instant;
    },
    isPhone() {
      let isPhone = false;
      if (document.body.clientWidth <= 768) {
        isPhone = true;
      }
      return isPhone;
    },
    sectionTotalTime() {
      let time = 0;
      let passage = this.list[0];
      let questions = passage.exam.exam_questions;
      questions.forEach(question => {
        if (question.userAnswer) {
          let spend = question.userAnswer.other_status
            ? this.totalTime(question.userAnswer.other_status.activities)
            : 0;
          time += spend;
        }
      });
      return this.instant.formatSecondToMMSS(time);
    }
  },
  watch: {},

  mounted() {},

  methods: {
    showAnswers(answers) {
      let answer = "";
      if (answers && answers.length > 0) {
        answers.forEach((item, index) => {
          if (index === answers.length - 1) {
            answer += item.answers[0];
          } else {
            answer += item.answers[0] + " | ";
          }
        });
      }
      return answer;
    },
    getResolve(question_id) {
      return 0;
    },
    showTotalTime(activities) {
      let totalTime = 0;
      activities.forEach((active) => {
        totalTime += active[0].testTime - active[active.length - 1].testTime;
      });
      if (totalTime < 0) totalTime = 1;
      return this.instant.formatSecondToMMSS(totalTime);
    },
    totalTime(activities) {
      let totalTime = 0;
      activities.forEach(active => {
        if (active.length > 0 && active[0]["testTime"] && active[active.length - 1]["testTime"]) {
          totalTime += active[0]["testTime"] - active[active.length - 1]["testTime"];
        }
      });
      if (totalTime < 0) totalTime = 1;
      return totalTime;
    },
    showTime(seconds) {
      let minute;
      let second;
      if (seconds && seconds > 0) {
        minute = Math.floor(seconds / 60);
        second = Math.floor(seconds) - minute * 60;
      } else {
        minute = 0;
        second = 0;
      }
      // 让个位数的时间正常显示
      if (minute <= 9) minute = "0" + minute;
      if (second <= 9) second = "0" + second;
      return `花費時間： ${minute}:${second}`;
    }
  }
};
</script>

<style scoped>
.answer-table {
  display: flex;
  justify-content: center;
}
.answer-table .table {
  width: auto;
  margin: 1rem 0;
}

table.table > tbody > tr > td.your_performance,
table.table > tbody > tr > td.score_level {
  text-align: left;
  padding: 10px;
}
table.table > tbody > tr > td.your_performance ul {
  list-style: disc;
  margin-bottom: 0;
}

.table-bordered {
  border: none;
}
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  vertical-align: middle;
  text-align: center;
}
.table .green-td {
  text-align: center;
  color: #fff;
  font-weight: bold;
  background-color: var(--themeColor);
}
td a {
  display: inline-block;
  width: 100%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
}
td a:hover {
  background-color: #eee;
}
table.table td,
table.table th {
  padding: 0 !important;
  height: 45px;
  line-height: 45px;
}
@media screen and (max-width: 768px) {
  .answer-table {
    width: 100%;
  }
  .answer-table table.table {
    width: 100%;
    margin: 0;
  }
}

@media screen and (min-width: 768px) {
  .table {
    position: relative;
    width: 100%;
    display: block;
  }
  thead {
    float: left;
  }
  tbody tr {
    display: inline-block;
  }
  th,
  td {
    height: 45px;
    padding: 0;
    line-height: 45px;
    display: block;
  }
  th {
    width: 150px;
  }
  .table td {
    padding: 0;
    min-width: 75px;
    max-width: 85px;
  }
  td a {
    cursor: pointer;
  }
}
</style>
